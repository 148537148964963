<template lang="">
    <div class="header"
    :class="{menuGamePage: this.$route.name == 'GamePage' && this.gameInfo.Статус == 1 && (authorization || capTeamAdmin == 'Модератор'),
    menuGamePageOpen: menuGamePageStatus && this.gameInfo.Статус == 1 && (authorization || capTeamAdmin == 'Модератор')}">
        <div class="menu">
            <div style="display: flex; align-items: center;">
                <router-link exact to="/"><img src="@/img/logo.png" alt="Логотип"></router-link>
                <router-link style="color: var(--mainColor); text-decoration: none;" v-if="authorization" class="login-entry" active-class="active-mobile" exact to="/admin">
                {{ adminLogin }}
                </router-link>
                <span class="login-entry" v-if="capLogin">
                {{ capLogin }}
                </span>
              </div>
              <div class="menu-btn">
                  <router-link class="btn-menu" active-class="active" exact to="/">Регулярка</router-link>
                  <router-link class="btn-menu" active-class="active" exact to="/teams">Команды</router-link>
                  <router-link class="btn-menu" active-class="active" exact to="/leaders">Лидеры</router-link>
                  <router-link class="btn-menu" active-class="active" exact to="/schedule">Расписание</router-link>
                  <router-link v-if="authorization || capTeamAdmin == 'Модератор'" class="btn-menu" active-class="active" exact to="/highlights">Хайлайты</router-link>
                  <router-link v-if="(this.settings && this.settings.МенюЗаявка == 1) || authorization" class="btn-menu" active-class="active" exact to="/request">Подать Заявку</router-link>
                    <a v-if="!authorization && !capTeam" @click="setModalStatus(true)" class="btn-menu">Вход</a>
                    <a v-if="authorization || capTeam" @click="exit()" class="btn-menu entry-success">Выход</a>
              </div>
          </div>
  
              <div class="menu-burger">
              <router-link exact to="/"><img class="logo-mobile" src="@/img/logo.png" alt="Логотип"></router-link>
              <div class="active-location">
                  <router-link class="btn-menu location" active-class="active-mobile-location" exact to="/">Регулярка</router-link>
                  <router-link class="btn-menu location" active-class="active-mobile-location" exact to="/teams">Команды</router-link>
                  <router-link class="btn-menu location" active-class="active-mobile-location" exact to="/leaders">Лидеры</router-link>
                  <router-link class="btn-menu location" active-class="active-mobile-location" exact to="/schedule">Расписание</router-link>  
                  <router-link v-if="authorization || capTeamAdmin == 'Модератор'" class="btn-menu location" active-class="active-mobile-location" exact to="/highlights">Хайлайты</router-link>
                  <router-link v-if="(this.settings && this.settings.МенюЗаявка == 1) || authorization" class="btn-menu location" active-class="active-mobile-location" exact to="/request">Подать Заявку</router-link>
              </div>
              <div @click="openMenu()" class="menu-burger__header">
                  <span></span>
              </div>
          </div>
  
          <div  @click="hideMenu()" class="menu-mobile">
              <transition name="fade">
              <div v-if="menuMobile" class="back-menu"></div>
          </transition>
          <transition name="slide">
              <div v-if="menuMobile" class="menu-btn">
                  <router-link style="color: var(--mainColor); text-decoration: none;" v-if="authorization" class="btn-menu" exact to="/admin">
                  {{ adminLogin }}
                  </router-link>
                  <router-link style="color: var(--mainColor); text-decoration: none;" v-if="capLogin" class="btn-menu" exact to="/">
                    {{ capLogin }}
                  </router-link>
                  <router-link class="btn-menu" active-class="active-mobile" exact to="/">Регулярка</router-link>
                  <router-link class="btn-menu" active-class="active-mobile" exact to="/teams">Команды</router-link>
                  <router-link class="btn-menu" active-class="active-mobile" exact to="/leaders">Лидеры</router-link>
                  <router-link class="btn-menu" active-class="active-mobile" exact to="/schedule">Расписание</router-link>
                  <router-link v-if="authorization || capTeamAdmin == 'Модератор'" class="btn-menu" active-class="active-mobile" exact to="/highlights">Хайлайты</router-link>
                  <router-link v-if="(this.settings && this.settings.МенюЗаявка == 1) || authorization" class="btn-menu" active-class="active-mobile" exact to="/request">Подать Заявку</router-link>
                    <a v-if="!authorization && !capTeam" @click="setModalStatus(true)" class="btn-menu">Вход</a>
                    <router-link v-if="authorization" class="btn-menu" active-class="active-mobile" exact to="/admin">Админка</router-link>
                    <a v-if="authorization || capTeam" @click="exit()" class="btn-menu entry-success">Выход</a>
              </div>
          </transition>
          </div>
    </div>
    <div v-if="this.$route.name == 'GamePage' && this.gameInfo.Статус == 1 && (authorization || capTeamAdmin == 'Модератор')" class="menuGamePageBtn"
    :class="{menuGamePageBtnOpen: menuGamePageStatus && this.gameInfo.Статус == 1 && (authorization || capTeamAdmin == 'Модератор')}" @click="changeMenuStatus()">
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 481.721 481.721" style="enable-background:new 0 0 481.721 481.721;" xml:space="preserve"><g><g><path d="M10.467,146.589l198.857,252.903c17.418,30.532,45.661,30.532,63.079,0l198.839-252.866 c3.88-5.533,8.072-15.41,8.923-22.118c2.735-21.738,4.908-65.178-21.444-65.178H23.013c-26.353,0-24.192,43.416-21.463,65.147 C2.395,131.185,6.587,141.051,10.467,146.589z" fill="#000000" style="fill: rgb(30, 144, 255);"></path></g></g></svg>
    </div>
  </template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      menuMobile: false,
      menuGamePageStatus: false,
    }
  },
  methods: {
    changeMenuStatus() {
      if (this.menuGamePageStatus) {
        this.menuGamePageStatus = false;
      } else {
        this.menuGamePageStatus = true;
      }
    },
    ...mapMutations({
      setModalStatus: 'post/setModalStatus',
    }),
    openMenu() {
      this.menuMobile = true;
    },
    hideMenu() {
      this.menuMobile = false;
    },
    ...mapActions({
      exit: 'post/exit',
    }),
  },
  computed: {
    ...mapState({
      modalEntry: state => state.post.modalEntry,
      authorization: state => state.post.authorization,
      capTeam: state => state.post.capTeam,
      capTeamAdmin: state => state.post.capTeamAdmin,
      modalStatus: state => state.post.modalStatus,

      adminLogin: state => state.post.adminLogin,
      capLogin: state => state.post.capLogin,

      gameInfo: state => state.post.gameInfo,

      settings: state => state.post.settings,
    }),
  }
}
</script>
<style scoped>
.header {
  height: 80px;
  padding: 10px;
  top: 0;
  width: 100%;
  overflow: auto;
  z-index: 1;
}

.menu {
  max-width: 1000px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: rgb(0, 0, 0);
  height: 100%;
  padding: 10px 10px 25px 10px;
  border-bottom: 1px solid #ebebeb;
}

.btn-menu {
  padding: 11px 10px 25px 10px;
  text-decoration: none;
  color: rgb(0, 0, 0);
  margin-left: 15px;
  font-weight: 500;
  transition: .3s;
  cursor: pointer;
}

.btn-menu:hover {
  color: var(--additionColor);
}

.menu img {
  width: 60px;
  margin-top: 10px;
  border-radius: 70px;
  margin-right: 10px;
}

.active {
  border-bottom: 2px solid var(--mainColor);
}

.active-mobile {
  color: var(--mainColor);
}

.btn-menu img {
  width: 20px;
}

.menu-burger,
.menu-mobile {
  display: none;
}

@media (max-width: 770px) {
  .menu {
    display: none;
  }

  .menu-mobile {
    display: block;
  }

  .menu-burger {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu-burger .logo-mobile {
    width: 55px;
    border-radius: 150px;
  }


  .back-menu {
    background: rgb(81, 87, 88, .4);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    height: 100%;
  }

  .menu-burger__header {
    cursor: pointer;
    width: 30px;
    height: 20px;
    margin-right: 10px;
  }

  .menu-burger__header span,
  .menu-burger__header:after,
  .menu-burger__header:before {
    height: 3px;
    width: 27px;
    position: absolute;
    background: var(--mainColor);
    margin: 20px auto;
    right: 20px;
    top: 29px;
  }

  .menu-burger__header span {
    top: 21px;
  }

  .menu-burger__header:after,
  .menu-burger__header:before {
    content: '';
  }

  .menu-burger__header:after {
    bottom: 5px;
  }

  .menu-burger__header:before {
    top: 13px;
  }

  .menu-mobile .menu-btn {
    z-index: 9999;
    width: 200px;
    display: flex;
    flex-direction: column;
    background: rgb(255 255 255 / 99%);
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .btn-menu {
    margin-left: 0;
  }

  .menu-mobile img {
    width: 100px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.6s ease;
}

.slide-leave-active {
  position: absolute;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.location {
  display: none;

}

.active-location {
  position: absolute;
  right: 70px;
}

.active-mobile-location {
  display: block;
  padding: 0;
  color: var(--mainColor);
}

.login-entry,
.entry-success {
  color: var(--additionColor);
  font-weight: 500;
}

.menuGamePage {
  margin-top: -100px;
  transition: all .3s;
}

.menuGamePageBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: -14px;
  margin-top: 35px;
  z-index: 99;
  position: relative;
}

.menuGamePageBtn svg {
  width: 15px;
  transition: all .3s;
  opacity: .5;
}

.menuGamePageBtn svg path {
  fill: var(--mainColor) !important;
}

.menuGamePageOpen {
  margin-top: 0 !important;
}

.menuGamePageBtnOpen {}

.menuGamePageBtnOpen svg {
  transform: rotate(180deg);
}


@media (max-width: 770px) {
  .menuGamePage {
    margin-top: 0;
  }

  .menuGamePageBtn {
    display: none;
  }
}
</style>