import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store/index.js"

const routes = [
  {
    id: 1,
    path: '/',
    name: 'RegularPage',
    component: () => import('@/pages/RegularPage'),
    meta: {
      title: "Регулярка",
    }
  },
  {
    id: 2,
    path: '/teams',
    name: 'TeamsPage',
    component: () => import('@/pages/TeamsPage'),
    meta: {
      title: "Команды",
    }
  },
  {
    id: 3,
    path: '/:division/:yearActive/team/:ID',
    name: 'TeamPage',
    component: () => import('@/pages/TeamPage'),
    meta: {
      title: "Страница Команды",
    }
  },
  {
    id: 4,
    path: '/schedule',
    name: 'SchedulePage',
    component: () => import('@/pages/SchedulePage'),
    meta: {
      title: "Расписание",
    }
  },

  {
    id: 5,
    path: '/:division/:yearActive/game/:ID',
    name: 'GamePage',
    component: () => import('@/pages/GamePage'),
    meta: {
      title: "Страница Матча",
    }
  },
  {
    id: 6,
    path: '/:division/:yearActive/player/:playerID',
    name: 'PlayerPage',
    component: () => import('@/pages/PlayerPage'),
    meta: {
      title: "Страница Игрока",
    }
  },
  {
    id: 7,
    path: '/request',
    name: 'RequestPage',
    component: () => import('@/pages/RequestPage'),
    meta: {
      title: "Подать Заявку",
    }
  },

  {
    id: 8,
    path: '/:division/:yearActive/start/:ID',
    name: 'GameStartPage',
    component: () => import('@/pages/GameStartPage'),
    meta: {
      title: "Начать Матч",
    }
  },

  {
    id: 9,
    path: '/:leaders',
    name: 'LeadersPage',
    component: () => import('@/pages/LeadersPage'),
    meta: {
      title: "Лидеры",
    },
  },
  {
    id: 10,
    path: '/admin',
    name: 'AdminPage',
    component: () => import('@/pages/AdminPage'),
    meta: {
      title: "Админка",
    }
  },
  {
    id: 11,
    path: '/:division/:yearActive/create',
    name: 'GameCreatePage',
    component: () => import('@/pages/GameCreatePage'),
    meta: {
      title: "Товарищеский матч",
    }
  },
  {
    id: 13,
    path: '/stream',
    name: 'StreamViewer',
    component: () => import('@/pages/StreamViewer'),
    meta: {
      title: "Запуска трансляции",
    }
  },
  {
    id: 12,
    path: '/stream/start',
    name: 'StreamStart',
    component: () => import('@/pages/StreamStart'),
    meta: {
      title: "Запуска трансляции",
    }
  },
  {
    id: 13,
    path: '/:division/:yearActive/game/:gameID/highlights',
    name: 'GameHighlights',
    component: () => import('@/pages/GameHighlights'),
    meta: {
      title: "Создание хайлайтов матча",
    }
  },
  {
    id: 14,
    path: '/highlights',
    name: 'ReadyHighlights',
    component: () => import('@/pages/ReadyHighlights'),
    meta: {
      title: "Готовые хайлайты",
    }
  },

]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
})

router.beforeEach((to, from, next) => {

  document.title = `${to.meta.title}`;

  // if (to.name == "AdminPage") {
  //   if (!store._state.data.post.authorization) {
  //     next({
  //       path: '/'
  //     })
  //   } else {
  //     next();
  //   }
  // } else {
  //   next();
  // }

  next();
})

export default router